import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import {
  Box,
  Container,
  Typography,
  Button,
  Modal,
  Slide,
  Fade,
} from "@mui/material";
import { auth, db, functions } from "../firebase";
import Header from "../components/Header";
import DreamForm from "../components/DreamForm";
import LoadingScreen from "../components/LoadingScreen";
import SavedDreamDetail from "../components/SavedDreamDetail";
import MyDreams from "../components/MyDreams";
import PurchaseCoinsModal from "../components/PurchaseCoinsModal";
import {
  fetchUserData,
  callChatGPT,
  validateAndAnalyzeDream,
} from "../utils/dashboardUtils";
import { httpsCallable } from "firebase/functions";
import { useStripe } from "@stripe/react-stripe-js";
import { doc, onSnapshot } from "firebase/firestore";
import starsImg from "../images/StarsGold.png"; // Import the stars image
import { keyframes } from "@emotion/react";

// Keyframes for fade-in animation
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 0.65;
  }
`;

const starStyles = [
  {
    top: "15%",
    left: "15%",
    transform: "rotate(20deg) scale(3)",
    delay: "0s",
  },
  {
    top: "18%",
    left: "65%",
    transform: "rotate(-150deg) scale(3.2)",
    delay: "0.2s",
  },
  {
    top: "27%",
    left: "25%",
    transform: "rotate(15deg) scale(2.5)",
    delay: "0.4s",
  },
  {
    top: "47%",
    left: "50%",
    transform: "rotate(10deg) scale(2.5)",
    delay: "0.6s",
  },
  {
    top: "75%",
    left: "10%",
    transform: "rotate(20deg) scale(2.5)",
    delay: "0.8s",
  },
  {
    top: "77%",
    left: "70%",
    transform: "rotate(-120deg) scale(2.5)",
    delay: "1s",
  },
  {
    top: "90%",
    left: "55%",
    transform: "rotate(25deg) scale(2.4)",
    delay: "1.2s",
  },
];

function Dashboard() {
  const [userEmail, setUserEmail] = useState("");
  const [coins, setCoins] = useState(0);
  const [loading, setLoading] = useState(true);
  const [dream, setDream] = useState("");
  const [error, setError] = useState("");
  const [analyzing, setAnalyzing] = useState(false);
  const [savedDreams, setSavedDreams] = useState([]);
  const [selectedDream, setSelectedDream] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [thankYouModalOpen, setThankYouModalOpen] = useState(false);
  const [checked, setChecked] = useState(false);
  const navigate = useNavigate();
  const stripe = useStripe();

  useEffect(() => {
    fetchUserData(navigate, setUserEmail, setCoins, setSavedDreams, setLoading);
    setChecked(true);
  }, [navigate]);

  useEffect(() => {
    if (auth.currentUser) {
      const userDocRef = doc(db, "users", auth.currentUser.uid);
      const unsubscribe = onSnapshot(userDocRef, (docSnapshot) => {
        if (docSnapshot.exists()) {
          const userData = docSnapshot.data();
          setCoins(userData.coins);
        }
      });
      return () => unsubscribe();
    }
  }, []);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      localStorage.removeItem("isLoggedIn");
      localStorage.removeItem("userEmail");
      navigate("/login");
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  const handleAnalyze = useCallback(async () => {
    if (!dream) {
      setError("Prašome aprašyti savo sapną");
      return;
    }
    if (coins <= 0) {
      setError("Nepakanka žetonų analizuoti sapnui");
      setModalOpen(true);
      return;
    }
    setError("");
    setAnalyzing(true);

    try {
      await validateAndAnalyzeDream(
        dream,
        setCoins,
        setError,
        callChatGPT,
        auth,
        db,
        setSavedDreams,
        setSelectedDream
      );
    } catch (error) {
      setError("Įvyko klaida analizuojant. Pamėginkite vėliau");
      console.error(error);
    } finally {
      setAnalyzing(false);
    }
  }, [dream, coins]);

  const handleErrorClose = () => {
    setError(null);
  };

  const handleCardClick = (dream) => {
    setSelectedDream(dream);
  };

  const handleBackToMain = () => {
    setSelectedDream(null);
    setChecked(false);
    setTimeout(() => {
      setChecked(true);
    }, 1);
  };

  const handleDeleteDream = (dreamToDelete) => {
    setSavedDreams((prev) => prev.filter((dream) => dream !== dreamToDelete));
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleThankYouClose = () => {
    setThankYouModalOpen(false);
  };

  const handlePurchase = async (priceId, coinsToAdd) => {
    setLoading(true);
    const createCheckoutSession = httpsCallable(
      functions,
      "createStripeCheckoutSession"
    );

    const userId = auth.currentUser ? auth.currentUser.uid : null;
    if (!userId) {
      console.error("User is not authenticated");
      setLoading(false);
      return;
    }

    try {
      const { data } = await createCheckoutSession({
        priceId,
        successUrl: `${window.location.origin}/dashboard?success=true`,
        cancelUrl: `${window.location.origin}/dashboard`,
        metadata: {
          coins: coinsToAdd,
        },
        client_reference_id: userId,
      });

      const { sessionId } = data;
      const result = await stripe.redirectToCheckout({ sessionId });

      if (result.error) {
        console.error(result.error.message);
      }
    } catch (error) {
      console.error("Error creating Stripe checkout session:", error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <LoadingScreen />;
  }

  const userInitial = userEmail.charAt(0).toUpperCase();

  return (
    <Box
      sx={{
        flexGrow: 1,
        position: "relative",
        height: "100vh",
        minHeight: "-webkit-fill-available", // Ensure it fills the available height on mobile devices
        height: "calc(var(--vh, 1vh) * 100)", // Dynamically adjust based on viewport height
        overflowY: "auto", // Allow vertical scrolling only
        overflowX: "hidden", // Prevent horizontal scrolling
        backgroundColor: "#2C3E50",
        zIndex: 2,
      }}
    >
      {starStyles.map((style, index) => (
        <Box
          key={index}
          component="img"
          src={starsImg}
          alt="Star"
          sx={{
            position: "absolute",
            ...style,
            animation: `${fadeIn} 3s ease-in-out forwards`,
            animationDelay: style.delay,
            width: "80px", // Adjust size as needed
            zIndex: 0, // Place stars behind the content
            opacity: 0, // Ensure stars are initially invisible
          }}
        />
      ))}
      <Header
        coins={coins}
        userInitial={userInitial}
        onLogout={handleLogout}
        setModalOpen={setModalOpen}
        sx={{ zIndex: 3 }} // Ensure Header is on top
      />
      <Slide direction="right" in={checked} mountOnEnter unmountOnExit>
        <Fade in={checked}>
          <Container
            sx={{
              zIndex: 2,
              position: "relative",
              backgroundColor: "transparent",
              padding: { xs: "0", md: "0 20px" }, // Remove horizontal padding on mobile
            }}
          >
            <Box
              sx={{
                mt: 3,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                zIndex: 2, // Ensuring this content is above other layers
              }}
            >
              {!selectedDream ? (
                <>
                  <DreamForm
                    dream={dream}
                    onChange={(e) => setDream(e.target.value)}
                    onSubmit={handleAnalyze}
                    analyzing={analyzing}
                    error={error}
                    onErrorClose={handleErrorClose}
                  />
                  <MyDreams
                    savedDreams={savedDreams}
                    handleCardClick={handleCardClick}
                  />
                </>
              ) : (
                <SavedDreamDetail
                  dream={selectedDream}
                  onBack={handleBackToMain}
                  onDelete={handleDeleteDream}
                  setChecked={setChecked}
                />
              )}
            </Box>
          </Container>
        </Fade>
      </Slide>
      <PurchaseCoinsModal
        open={modalOpen}
        onClose={handleCloseModal}
        onPurchase={handlePurchase}
      />
      <Modal open={thankYouModalOpen} onClose={handleThankYouClose} disablePortal>
        <div
          style={{
            padding: "20px",
            backgroundColor: "white",
            margin: "auto",
            marginTop: "10%",
          }}
        >
          <Typography variant="h6">Ačiū už pirkinį!</Typography>
          <Button variant="contained" onClick={handleThankYouClose}>
            X
          </Button>
        </div>
      </Modal>
    </Box>
  );
  
}

export default Dashboard;
