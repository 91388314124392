import React, { useState } from "react";
import { createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { auth, db } from "../firebase";
import { Box, Button, Typography, CircularProgress, Alert } from "@mui/material";
import { styled } from '@mui/system';
import TextField from '@mui/material/TextField';

const WhiteTextField = styled(TextField)({
  '& .MuiInputBase-input': {
    color: '#2C3E50',
  },
  '& label': {
    color: '#2C3E50',
  },
  '& label.Mui-focused': {
    color: '#2C3E50',
  },
  '& .MuiInput-underline:before': {
    borderBottomColor: '#2C3E50',
  },
  '& .MuiInput-underline:hover:before': {
    borderBottomColor: '#2C3E50',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#2C3E50',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#2C3E50',
    },
    '&:hover fieldset': {
      borderColor: '#2C3E50',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#2C3E50',
    },
  },
  '&:-webkit-autofill': {
    transition: 'background-color 5000s ease-in-out 0s',
    WebkitTextFillColor: '#2C3E50 !important',
  },
});

const RegisterCard = ({ setIsRegister }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("error");
  const [loading, setLoading] = useState(false);

  const handleRegister = async () => {
    if (!email || !password || !confirmPassword) {
      setMessage("Prašome užpildyti visus laukus");
      setMessageType("error");
      return;
    }

    if (password !== confirmPassword) {
      setMessage("Slaptažodžiai nesutampa");
      setMessageType("error");
      return;
    }

    setLoading(true);
    setMessage("");
    setMessageType("error");

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      await setDoc(doc(db, "users", user.uid), { email, coins: 5, dreams: [] });

      await sendEmailVerification(user); // Send verification email

      setMessage("Paskyra sukurta. Prašome atidaryti patvirtinimo laišką kurį išsiuntėme į jūsų el. paštą");
      setMessageType("success");
      setLoading(false);

    } catch (error) {
      if (error.code === "auth/email-already-in-use") {
        setMessage("Šis el. pašto adresas jau naudojamas");
      } else if (error.code === "auth/weak-password") {
        setMessage("Slaptažodis turi turėti bent 6 simbolius");
      } else if (error.code === "auth/invalid-email") {
        setMessage("Klaidingas el.pašto adresas");
      } else {
        setMessage("Įvyko klaida. Bandykite dar kartą");
      }
      setMessageType("error");
      setLoading(false);
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <Typography component="h1" variant="h5" sx={{ color: '#2C3E50' }}>
        Registruotis
      </Typography>
      <Box component="form" noValidate sx={{ mt: 1 }}>
        <WhiteTextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="El. paštas"
          name="email"
          autoComplete="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <WhiteTextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Slaptažodis"
          type="password"
          id="password"
          autoComplete="new-password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <WhiteTextField
          margin="normal"
          required
          fullWidth
          name="confirmPassword"
          label="Patvirtinkite slaptažodį"
          type="password"
          id="confirmPassword"
          autoComplete="new-password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        {message && (
          <Alert severity={messageType} sx={{ mt: 2 }}>
            {message}
          </Alert>
        )}
        <Button
          type="button"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2,backgroundColor:"#1ABC9C" }}
          onClick={handleRegister}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : "Registruotis"}
        </Button>
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <Button onClick={() => setIsRegister(false)} sx={{ color: '#2C3E50' }}>
            Jau turite paskyrą? Prisijungti
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default RegisterCard;
