import React from "react";
import { AppBar, Toolbar, Avatar, Box, Typography, IconButton } from "@mui/material";
import { deepOrange } from "@mui/material/colors";
import { Logout, MonetizationOn } from "@mui/icons-material";
import logo from "../images/logo.png";

const Header = ({ coins, userInitial, onLogout, setModalOpen }) => {
  return (
    <AppBar position="static" sx={{
      bgcolor: '#34495E',
      boxShadow: '5px',
      zIndex: 3, // Ensure the Header is above other content
      opacity: 1, // Explicitly ensure full opacity
    }}>
      <Toolbar>
        <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1, mr:3 }}>
          <Avatar src={logo} alt="Logo" sx={{ width: 70, height: 70 }} />
          <Typography variant="h5" component="div">
            Sapnasis
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", mr: 2 }} onClick={() => setModalOpen(true)}>
          <MonetizationOn sx={{ mr: 0.5 }} style={{ fill: "#FFD700" }} />
          <Typography variant="h6">{coins}</Typography>
        </Box>
        <Avatar sx={{ bgcolor: deepOrange[500], mr: 1 }}>
          {userInitial}
        </Avatar>
        <IconButton color="inherit" onClick={onLogout}>
          <Logout />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
