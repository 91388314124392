import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Rating
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ReviewCarousel = () => {
  const [reviews, setReviews] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch('/reviews.json')
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => setReviews(data))
      .catch(error => {
        console.error('There was a problem with the fetch operation:', error);
        setError(error);
      });
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 7000,
    arrows: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false
        }
      }
    ]
  };

  const formatName = (name) => {
    const [firstName, lastName] = name.split(' ');
    return `${firstName} ${lastName.charAt(0)}.`;
  };

  return (
    <Box sx={{ mt: 2, mb: 24 }}>
      <style>
        {`
          .slick-dots li button:before {
            color: white; /* Make the dots white */
            font-size: 16px; /* Make the dots bigger */
          }
          .slick-dots li.slick-active button:before {
            color: white; /* Make the active dot white as well */
            font-size: 16px; /* Make the active dot bigger */
          }
        `}
      </style>
      {error ? (
        <Typography variant="body1" color="error">
          There was a problem loading the reviews.
        </Typography>
      ) : (
        <Slider {...settings}>
          {reviews.map((review, index) => (
            <Card key={index} sx={{ bgcolor: "#ECF0F1", color: "white", textAlign: "left" }}>
              <CardContent sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <PersonIcon sx={{ fontSize: 40, mr: 1, color:"#2C3E50" }} />
                  <Typography variant="h6" color={"#2C3E50"}>
                    {formatName(review.name)}
                  </Typography>
                </Box>
                <Typography variant="body1" sx={{ flexGrow: 1, height: "100px", fontSize: 18,color:"#2C3E50" }}>
                  {review.comment}
                </Typography>
                <Box sx={{ textAlign: 'right', mt: 2 }}>
                  <Rating
                    value={review.stars}
                    readOnly
                    size="small"
                    sx={{
                      '& .MuiRating-iconFilled': {
                        color: '#2C3E50',
                      },
                      '& .MuiRating-iconHover': {
                        color: '#2C3E50',
                      },
                    }}
                  />
                </Box>
              </CardContent>
            </Card>
          ))}
        </Slider>
      )}
    </Box>
  );
};

export default ReviewCarousel;
