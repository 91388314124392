import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Avatar,
  Box,
  Container,
  CssBaseline,
  Typography,
} from "@mui/material";
import { keyframes } from "@emotion/react";
import LoginCard from "../components/LoginCard";
import RegisterCard from "../components/RegisterCard";
import logo from "../images/logo.png"; // Import your logo image
import starsImg from "../images/StarsGold.png"; // Import the stars image

// Keyframes for fade-in and slide-in from the bottom animation
const fadeInSlideUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 0.65;
  }
`;

const appBarHeight = 64; // Set a fixed height for the AppBar

function Login() {
  const [isRegister, setIsRegister] = useState(true);

  const starStyles = [
    {
      top: "15%",
      left: "25%",
      transform: "rotate(20deg) scale(3)",
      delay: "0.2s",
    },
    {
      top: "15%",
      left: "87%",
      transform: "rotate(-55deg) scale(1.5)",
      delay: "0.4s",
    },
    {
      top: "35%",
      left: "70%",
      transform: "rotate(-150deg) scale(3.2)",
      delay: "0.5s",
    },
    {
      top: "80%",
      left: "55%",
      transform: "rotate(180deg) scale(2.5)",
      delay: "0.6s",
    },
    {
      top: "80%",
      left: "-3.5%",
      transform: "rotate(-58deg) scale(1.5)",
      delay: "0.7s",
    },
  ];

  return (
    <>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          bgcolor: "#34495E",
          boxShadow: "5px",
        }}
      >
        <Toolbar sx={{ minHeight: appBarHeight }}>
          <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
            <Avatar src={logo} alt="Logo" sx={{ width: 70, height: 70 }} />
            <Typography variant="h5" component="div" sx={{ color: "#ECF0F1" }}>
              Sapnasis
            </Typography>
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          position: "relative",
          height: "100vh",
          backgroundColor: "#2C3E50",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
        }}
      >
        {starStyles.map((style, index) => (
          <Box
            key={index}
            component="img"
            src={starsImg}
            alt="Star"
            sx={{
              position: "absolute",
              ...style,
              animation: `${fadeIn} 3s ease-in-out forwards`,
              animationDelay: style.delay,
              width: "80px", // Adjust size as needed
              zIndex: 0, // Place behind the content
              opacity: 0, // Ensure stars are initially invisible
            }}
          />
        ))}
        <Container
          component="main"
          maxWidth="xs"
          sx={{
            animation: `${fadeInSlideUp} 0.8s ease-out`,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "#ECF0F1",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "5px",
            zIndex: 1, // Ensure it stays above the stars
            position: "relative",
            margin: "0 20px", // Add this line for space on the sides
          }}
        >
          <Box elevation={3} style={{ width: "100%" }}>
            {isRegister ? (
              <RegisterCard setIsRegister={setIsRegister} />
            ) : (
              <LoginCard setIsRegister={setIsRegister} />
            )}
          </Box>
        </Container>
      </Box>
    </>
  );
}

export default Login;
