import React from 'react';
import { Typography, Box, Grid } from '@mui/material';
import { NightsStay } from '@mui/icons-material';
import SavedDreamCard from './SavedDreamCard';

const MyDreams = ({ savedDreams, handleCardClick }) => {
  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 1 }}>
        <NightsStay sx={{ color: "#A29BFE", mr: 1, fontSize: "32px" }} />
        <Typography variant="h4" sx={{ color: "#FAF9F6", textTransform: "uppercase" }}>
          Mano Sapnai
        </Typography>
        <NightsStay sx={{ color: "#A29BFE", ml: 1, fontSize: "32px" }} />
      </Box>
      <Box
        sx={{
          width: '100%',
          maxHeight: '300px', // Set the height to fit the number of cards properly
          overflowY: 'auto',
          padding: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'flex-start', // Align items to the start
        }}
      >
        {savedDreams.length > 0 ? (
          <Grid container spacing={1} justifyContent="center">
            {savedDreams.map((dream, index) => (
              <Grid item xs={11} key={index}>
                <SavedDreamCard dream={dream} onClick={handleCardClick} />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Typography variant="h6" sx={{ color: "#FAF9F6", textAlign: 'center', mt: 2 }}>
            Kolkas nėra sapnų...
          </Typography>
        )}
      </Box>
    </>
  );
};

export default MyDreams;
