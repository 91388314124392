import React, { useState, useEffect } from "react";
import { Box, Typography, Card, CardContent, CardActions, IconButton, Slide, Fade } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { doc, updateDoc, arrayRemove } from "firebase/firestore";
import { db, auth } from "../firebase"; // Import your firebase config

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const year = date.getFullYear();
  return `${month}/${day}/${year}`;
};

const SavedDreamDetail = ({ dream, onBack, onDelete }) => {
  const [checkedModal, setCheckedModal] = useState(false);

  useEffect(() => {
    setCheckedModal(true);
  }, []);

  const handleDelete = async () => {
    const user = auth.currentUser;
    const userDocRef = doc(db, "users", user.uid);

    try {
      await updateDoc(userDocRef, {
        dreams: arrayRemove(dream)
      });
      onDelete(dream); // Update the state in the parent component
      onBack(); // Navigate back to the main screen
    } catch (error) {
      console.error("Error deleting dream:", error);
    }
  };

  return (
    <Slide direction="right" in={checkedModal} mountOnEnter unmountOnExit>
      <Fade in={checkedModal}>
        <Box sx={{ width: "100%", maxWidth: 600, mb: 4 }}>
          <Card
            sx={{
              p: 2,
              height: '680px', // Fixed height for the card
              display: 'flex',
              flexDirection: 'column',
              borderRadius: 3,
              backgroundColor: '#5A6B82', // Transparent background color
    boxShadow: 5, // Box shadow
            }}
          >
            <CardActions sx={{ display: 'flex', justifyContent: 'space-between'}}>
              <IconButton aria-label="back" onClick={onBack} sx={{ color: '#A29BFE', fontSize: '36px' }}>
                <ArrowBackIosIcon fontSize="inherit" />
              </IconButton>
              <IconButton aria-label="delete" onClick={handleDelete} sx={{ color: '#A29BFE', fontSize: '36px' }}>
                <DeleteIcon fontSize="inherit" />
              </IconButton>
            </CardActions>
            <CardContent
              sx={{
                flex: 1,
                overflowY: "auto",
              }}
            >
              <Typography variant="h4" sx={{ mb: 2 ,color: 'white'}}>
                Sapno detalės
              </Typography>
              <Typography variant="h5" sx={{ mb: 1 ,color: 'white'}}>
                Aprašymas:
              </Typography>
              <Typography variant="body1" sx={{ mb: 2 ,color: 'white'}}>
                {dream.description}
              </Typography>
              <Typography variant="h5" sx={{ mb: 1 ,color: 'white'}}>
                Analizė:
              </Typography>
              <Typography variant="body1" sx={{ mb: 2 ,color: 'white'}}>
                {dream.interpretation}
              </Typography>
              <Typography variant="h5" sx={{ mb: 1 ,color: 'white'}}>
                Data:
              </Typography>
              <Typography variant="body1" sx={{ mb: 2 ,color: 'white'}}>
                {formatDate(dream.date)}
              </Typography>
            </CardContent>
          </Card>
        </Box>
      </Fade>
    </Slide>
  );
};

export default SavedDreamDetail;
