import React from "react";
import { Card, CardContent, Typography, CardActionArea } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const SavedDreamCard = ({ dream, onClick }) => {
  const truncatedDescription = dream.description.length > 18
    ? dream.description.substring(0, 18) + "..."
    : dream.description;

  return (
    <Card sx={{ mb: 1,
    backgroundColor: '#5A6B82', // Transparent background color
    boxShadow: 5, // Box shadow
    borderRadius:2,
    ':hover': { boxShadow: 6, backgroundColor:"#3F00FF" }}}>
      <CardActionArea onClick={() => onClick(dream)}>
        <CardContent sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', zIndex:1 }}>
          <Typography variant="h6" color={"white"}>
            {truncatedDescription}
          </Typography>
          <ArrowForwardIosIcon sx={{color:"#A29BFE"}}/>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default SavedDreamCard;
