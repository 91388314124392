// utils/dashboardUtils.js
import { doc, getDoc, runTransaction } from "firebase/firestore";
import { auth, db } from "../firebase";
import axios from "axios";

export const fetchUserData = async (navigate, setUserEmail, setCoins, setSavedDreams, setLoading) => {
  try {
    const user = await new Promise((resolve) => {
      const unsubscribe = auth.onAuthStateChanged((user) => {
        unsubscribe();
        resolve(user);
      });
    });

    if (!user) {
      navigate("/login");
      return;
    }

    const userDoc = await getDoc(doc(db, "users", user.uid));
    if (userDoc.exists()) {
      const userData = userDoc.data();
      setUserEmail(userData.email);
      setCoins(userData.coins);
      setSavedDreams(userData.dreams || []);
    } else {
      console.error("No such document!");
    }
  } catch (error) {
    console.error("Error fetching user data:", error);
  } finally {
    setTimeout(() => setLoading(false), 2000);
  }
};

export const callChatGPT = async (prompt) => {
  const API_KEY = process.env.REACT_APP_OPENAI_API_KEY;
  const apiUrl = "https://api.openai.com/v1/chat/completions";
  const maxRetries = 4;
  let retries = 0;

  while (retries < maxRetries) {
    try {
      const response = await axios.post(
        apiUrl,
        {
          model: "gpt-4o",
          messages: [{ role: "user", content: prompt }],
          max_tokens: 1000,
        },
        {
          headers: {
            Authorization: `Bearer ${API_KEY}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data.choices[0].message.content.trim();
    } catch (error) {
      if (error.response && error.response.status === 429) {
        retries++;
        const delay = Math.pow(2, retries) * 1000;
        console.warn(`Rate limited. Retrying in ${delay / 1000} seconds...`);
        await new Promise((resolve) => setTimeout(resolve, delay));
      } else {
        console.error(
          "Error calling ChatGPT API:",
          error.response ? error.response.data : error.message
        );
        throw error;
      }
    }
  }

  throw new Error("Max retries reached. Please try again later.");
};

export const validateAndAnalyzeDream = async (
  dream, 
  setCoins, 
  setError, 
  callChatGPT, 
  auth, 
  db, 
  setSavedDreams,
  setSelectedDream,
) => {
  const validationPrompt = `Ar šis tekstas yra sapno aprašymas? Sapnas tai miegančiam kylantys vaizdai, mintys. 
  Jei tai nėra sapno aprašymas arba yra kažkas kito, atsakyk "ne". Jei tai yra sapno aprašymas, atsakyk "taip". 
  Būtinai atsakyk tiksliai kaip parašiau: "ne" arba "taip". Nerašyk nieko daugiau ir nesuteik jokių kitų instrukcijų ar informacijos.\n\n Sapnavau: ${dream}`;
  const validationResponse = await callChatGPT(validationPrompt)
  console.log(validationResponse);

  if (validationResponse.toLowerCase() !== "taip") {
    const user = auth.currentUser;
    const userDocRef = doc(db, "users", user.uid);

    await runTransaction(db, async (transaction) => {
      const userDoc = await transaction.get(userDocRef);
      if (!userDoc.exists()) {
        throw new Error("User does not exist!");
      }
      const newCoins = userDoc.data().coins - 1;
      transaction.update(userDocRef, { coins: newCoins });
      setCoins(newCoins);
    });

    setError("Tai nėra sapno aprašymas. Jūs ką tik iššvaistėte vieną savo žetoną...");
    return;
  }

  const prompt = `Išsamiai išanalizuok šį sapną, pateikdamas pačią geriausią ir tiksliausią interpretaciją.
   Atsakyk tikslia ir taisyklinga lietuvių kalba, pateikdamas viską, ką reikia žinoti apie šį sapną, kad galėčiau suprasti jo pilną reikšmę ir ką jis atskleidžia realiame gyvenime.
    Prašau pateikti interpretaciją be jokių papildomų instrukcijų ar nereikalingos informacijos:\n\n${dream}`;

  const result = await callChatGPT(prompt);

  const user = auth.currentUser;
  const userDocRef = doc(db, "users", user.uid);
  const newDream = {
    description: dream,
    interpretation: result,
    date: new Date().toISOString(),
  };

  await runTransaction(db, async (transaction) => {
    const userDoc = await transaction.get(userDocRef);
    if (!userDoc.exists()) {
      throw new Error("User does not exist!");
    }
    const newCoins = userDoc.data().coins - 1;
    const newDreams = [...userDoc.data().dreams, newDream];
    transaction.update(userDocRef, {
      coins: newCoins,
      dreams: newDreams,
    });
    setCoins(newCoins);
    setSavedDreams(newDreams);
  });

  setSelectedDream(newDream)
};
