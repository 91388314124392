import React from "react";
import { Box, Typography, Button, IconButton, Grid } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { keyframes } from "@emotion/react";

const fadeInSlideDown = keyframes`
  from {
    opacity: 0;
    transform: translateY(-100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const PurchaseCoinsModal = ({ open, onClose, onPurchase }) => {
  if (!open) return null;

  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(0, 0, 0, 0.8)",
        animation: `${fadeInSlideDown} 0.5s ease-out`,
        zIndex:10
      }}
    >
      <Box
        sx={{
          padding: 4,
          position: "relative",
          width: "80%",
          maxWidth: "500px",
          textAlign: "center",
          backgroundColor: "rgba(31, 48, 94, 1)",
          borderRadius:5,
          color:"white",
          boxShadow: '5px',
        }}
      >
        <IconButton
          sx={{ position: "absolute", top: 8, right: 8, color:"white" }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h4" sx={{ mb: 2 }}>
          Pirkti Žetonus
        </Typography>
        <Typography variant="body1" sx={{ mb: 4 }}>
          Pasirinkite jums norimą paketą, kad toliau galėtumėte analizuoti sapnus
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>Mini</Typography>
            <Button
              variant="contained"
              fullWidth
              onClick={() => onPurchase("price_1PLhgpDSm3CeQT7opwmt2oU5", 20)}
            >
              20 žetonų už €4.99
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Typography>Medi</Typography>
            <Button
              variant="contained"
              fullWidth
              onClick={() => onPurchase("price_1PLhgnDSm3CeQT7oIZfYZJia", 100)}
            >
              100 žetonų už €19.99
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Typography>Maxi</Typography>
            <Button
              variant="contained"
              fullWidth
              onClick={() => onPurchase("price_1PLhgkDSm3CeQT7oPeFlh4Ap", 350)}
            >
              350 žetonų už €49.99
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default PurchaseCoinsModal;
