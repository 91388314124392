import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../firebase';
import { Box, Button, Typography, CircularProgress, Alert, Link, Grid } from '@mui/material';
import { styled } from '@mui/system';
import TextField from '@mui/material/TextField';

const WhiteTextField = styled(TextField)({
  '& .MuiInputBase-input': {
    color: '#2C3E50',
  },
  '& label': {
    color: '#2C3E50',
  },
  '& label.Mui-focused': {
    color: '#2C3E50',
  },
  '& .MuiInput-underline:before': {
    borderBottomColor: '#2C3E50',
  },
  '& .MuiInput-underline:hover:before': {
    borderBottomColor: '#2C3E50',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#2C3E50',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#2C3E50',
    },
    '&:hover fieldset': {
      borderColor: '#2C3E50',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#2C3E50',
    },
  },
});

const LoginCard = ({ setIsRegister }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [resetEmailSent, setResetEmailSent] = useState(false); // State for tracking password reset email
  const navigate = useNavigate();

  const handleLogin = async () => {
    if (!email || !password) {
      setError('Prašome įvesti el. paštą ir slaptažodį');
      return;
    }

    setLoading(true);
    setError('');

    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      if (user.emailVerified) {
        localStorage.setItem('isLoggedIn', 'true');
        localStorage.setItem('userEmail', email);
        navigate('/dashboard');
      } else {
        setError('Prašome patvirtinti savo el. paštą');
        await auth.signOut(); // Optionally sign out the user
      }
    } catch (error) {
      if (error.code === 'auth/invalid-credential') {
        setError('Neteisingi prisijungimo duomenys');
      } else {
        setError('Įvyko klaida. Bandykite dar kartą');
      }
    } finally {
      setLoading(false);
    }
  };

  const handlePasswordReset = async () => {
    if (!email) {
      setError('Prašome įvesti savo el. paštą');
      return;
    }

    try {
      await sendPasswordResetEmail(auth, email);
      setResetEmailSent(true);
      setError(''); // Clear any previous error
    } catch (error) {
      setError('Įvyko klaida siunčiant slaptažodžio atstatymo laišką');
      console.error('Error sending password reset email:', error);
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Typography component="h1" variant="h5" sx={{ color: '#2C3E50' }}>
        Prisijungti
      </Typography>
      <Box component="form" noValidate sx={{ mt: 1 }}>
        <WhiteTextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="El. paštas"
          name="email"
          autoComplete="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <WhiteTextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Slaptažodis"
          type="password"
          id="password"
          autoComplete="current-password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        {error && <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>}
        {resetEmailSent && <Alert severity="success" sx={{ mt: 2 }}>Slaptažodžio atstatymo laiškas išsiųstas!</Alert>}
        <Button
          type="button"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2,backgroundColor:"#1ABC9C" }}
          onClick={handleLogin}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : 'Prisijungti'}
        </Button>
        <Grid container sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <Grid item>
            <Link
              href="#"
              variant="body2"
              onClick={() => setIsRegister(true)}
              sx={{ color: '#2C3E50' }}
            >
              Neturite paskyros? Registruotis
            </Link>
          </Grid>
          <Grid item>
            <Link
              href="#"
              variant="body2"
              onClick={handlePasswordReset}
              sx={{ color: '#2C3E50' }}
            >
              Pamiršau slaptažodį
            </Link>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default LoginCard;
