import React, { useState } from 'react';
import { Box, Container, Typography, Link, Dialog, DialogTitle, DialogContent, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ReactMarkdown from 'react-markdown';

const Footer = () => {
  const [open, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState({ title: '', text: '' });

  const handleOpen = async (title, fileName) => {
    const content = await import(`../content/${fileName}`);
    const text = await fetch(content.default).then(response => response.text());
    setModalContent({ title, text });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box
      sx={{
        bgcolor: '#34495E',
        p: 2,
        mt: 4,
        pt:6,
        pb:12
      }}
      component="footer"
    >
      <Container maxWidth="lg">
        <Typography variant="h4" align="center" gutterBottom color="#ECF0F1">
          Sapnasis
        </Typography>
        <Typography variant="subtitle2" align="center" color="#ECF0F1" component="p">
          Pažinkite savo sapnus ir atraskite daugiau apie save!
        </Typography>
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
          <Link color="#ECF0F1" sx={{ mx: 1, textDecoration: "none" }} onClick={() => handleOpen('Privatumo politika', 'privacyPolicy.txt')}>
            Privatumo politika
          </Link>
          <Link color="#ECF0F1" sx={{ mx: 1, textDecoration: "none" }} onClick={() => handleOpen('Naudojimo sąlygos', 'termsOfUse.txt')}>
            Naudojimo sąlygos
          </Link>
          <Link color="#ECF0F1" sx={{ mx: 1, textDecoration: "none" }} onClick={() => handleOpen('Pagalba', 'help.txt')}>
            Pagalba
          </Link>
        </Box>
      </Container>

      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>
          {modalContent.title}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Typography variant="body1" component="div">
            <ReactMarkdown>{modalContent.text}</ReactMarkdown>
          </Typography>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default Footer;
