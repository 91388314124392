import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import { analytics } from './firebase'; // Import the initialized analytics instance
import { logEvent } from 'firebase/analytics'; // Import logEvent


function App() {
  return (
    <Router>
      <AppRoutes />
    </Router>
  );
}


function AppRoutes() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const isLoggedIn = localStorage.getItem('isLoggedIn');
    if (isLoggedIn) {
      navigate('/dashboard');
    }
  }, [navigate]);

  useEffect(() => {
    if (analytics) {
      console.log('Logging page view for', location.pathname);
      logEvent(analytics, 'page_view', { page_path: location.pathname });
    } else {
      console.log('Analytics is not initialized');
    }
  }, [location]);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/dashboard" element={<Dashboard />} />
    </Routes>
  );
}

export default App;
