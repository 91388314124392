import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { keyframes } from "@emotion/react";
import {
  AppBar,
  Toolbar,
  Avatar,
  Box,
  Container,
  Typography,
  Button,
  Grid,
  IconButton,
  Modal,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import HamburgerMenu from "react-hamburger-menu";
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";
import logo from "../images/logo.png";
import dreamerSvg from "../images/DreamerWomanWhiteGold.png";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import InsightsIcon from "@mui/icons-material/Insights";
import SecurityIcon from "@mui/icons-material/Security";
import Footer from "../components/Footer";
import ReviewCarousel from "../components/ReviewCarousel";
import starsImg from "../images/StarsGold.png";
import DoubleArrowIcon from "@mui/icons-material/KeyboardDoubleArrowDown";

function Home() {
  const navigate = useNavigate();
  const [imageLoaded, setImageLoaded] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [closing, setClosing] = useState(false);

  const handleStart = () => {
    navigate("/login");
  };

  const handleToggleMenu = () => {
    if (isMenuOpen) {
      setClosing(true); // Trigger closing animation
      setTimeout(() => {
        setIsMenuOpen(false);
        setClosing(false); // Reset closing state after animation duration
      }, 500); // Match this duration to your fadeOut animation duration
    } else {
      setIsMenuOpen(true);
    }
  };

  const fadeIn = keyframes`
    from {
      opacity: 0;
    }
    to {
      opacity: 0.65;
    }
  `;

  const fadeInFull = keyframes`
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  `;

  const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

  const bounce = keyframes`
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(10px);
    }
    60% {
      transform: translateY(5px);
    }
  `;

  const starStyles = [
    {
      top: "2.5%",
      left: "35%",
      transform: "rotate(20deg) scale(3)",
      delay: "0.5s",
    },
    {
      top: "18%",
      left: "35%",
      transform: "rotate(-150deg) scale(3.2)",
      delay: "1s",
    },
    {
      top: "30%",
      left: "25%",
      transform: "rotate(15deg) scale(2.5)",
      delay: "2s",
    },
    {
      top: "40%",
      left: "65%",
      transform: "rotate(-20deg) scale(2)",
      delay: "2s",
    },
    {
      top: "50%",
      left: "25%",
      transform: "rotate(10deg) scale(2.5)",
      delay: "2s",
    },
    {
      top: "60%",
      left: "55%",
      transform: "rotate(-15deg) scale(2.8)",
      delay: "2.5s",
    },
    {
      top: "71%",
      left: "35%",
      transform: "rotate(20deg) scale(3)",
      delay: "3s",
    },
    {
      top: "78%",
      left: "75%",
      transform: "rotate(-120deg) scale(2.5)",
      delay: "3.5s",
    },
    {
      top: "90%",
      left: "85%",
      transform: "rotate(25deg) scale(2.4)",
      delay: "4s",
    },
  ];

  const modalStarStyles = [
    {
      top: "10%",
      left: "20%",
      transform: "rotate(30deg) scale(3)",
      delay: "0s",
    },
    {
      top: "35%",
      left: "80%",
      transform: "rotate(-70deg) scale(2.5)",
      delay: "0.2s",
    },
    {
      top: "80%",
      left: "15%",
      transform: "rotate(145deg) scale(3.4)",
      delay: "0.4s",
    },
  ];

  useEffect(() => {
    const timer = setTimeout(() => setImageLoaded(true), 500);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <AppBar
        position="static"
        sx={{
          bgcolor: "#34495E",
          boxShadow: "5px",
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Avatar src={logo} alt="Logo" sx={{ width: 70, height: 70 }} />
            <Typography
              variant="h5"
              component="div"
              sx={{ ml: 2, color: "#ECF0F1" }}
            >
              Sapnasis
            </Typography>
          </Box>

          {/* Desktop Links */}
          <Box sx={{ display: { xs: "none", md: "block" } }}>
            <ScrollLink
              to="about-section"
              smooth={true}
              duration={500}
              style={{
                cursor: "pointer",
                margin: "0 20px",
                color: "#ECF0F1",
                textDecoration: "none",
              }}
            >
              Apie
            </ScrollLink>
            <ScrollLink
              to="dreams-section"
              smooth={true}
              duration={500}
              style={{
                cursor: "pointer",
                margin: "0 20px",
                color: "#ECF0F1",
                textDecoration: "none",
              }}
            >
              Sapnai
            </ScrollLink>
            <ScrollLink
              to="reviews-section"
              smooth={true}
              duration={500}
              style={{
                cursor: "pointer",
                margin: "0 20px",
                color: "#ECF0F1",
                textDecoration: "none",
              }}
            >
              Atsiliepimai
            </ScrollLink>
          </Box>

          {/* Mobile Hamburger Menu */}
          <Box sx={{ display: { xs: "block", md: "none" } }}>
            <HamburgerMenu
              isOpen={isMenuOpen}
              menuClicked={handleToggleMenu}
              width={30}
              height={20}
              strokeWidth={3}
              rotate={0}
              color="#ECF0F1"
              borderRadius={0}
              animationDuration={0.5}
            />
          </Box>
        </Toolbar>
      </AppBar>

      {/* Modal for Mobile Menu */}
      <Modal
        open={isMenuOpen || closing} // Keep modal open during fade-out
        onClose={handleToggleMenu}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          animation: closing
            ? `${fadeOut} 0.5s ease-in-out`
            : `${fadeInFull} 0.5s ease-in-out`,
        }}
      >
        <Box
          sx={{
            bgcolor: "#34495E",
            padding: 4,
            borderRadius: 2,
            outline: "none",
            width: "100%",
            height: "100%", // Make modal full-screen
            textAlign: "center",
            position: "relative", // Ensure proper positioning for close button
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* Add animated stars inside the modal */}
          {modalStarStyles.map((style, index) => (
            <Box
              key={index}
              component="img"
              src={starsImg}
              alt="Star"
              sx={{
                position: "absolute",
                ...style,
                animation: `${fadeInFull} 3s ease-in-out forwards`,
                animationDelay: style.delay,
                width: "80px", // Adjust size as needed
                zIndex: 0, // Ensure stars are behind the content
                opacity: 0, // Ensure stars are initially invisible
              }}
            />
          ))}

          <IconButton
            onClick={handleToggleMenu}
            sx={{
              position: "absolute",
              top: 42,
              right: 4,
              color: "#ECF0F1",
              fontSize: "2rem",
            }}
          >
            <CloseIcon sx={{ fontSize: "2.5rem" }} />
          </IconButton>
          <ScrollLink
            to="about-section"
            smooth={true}
            duration={500}
            onClick={handleToggleMenu}
            style={{
              cursor: "pointer",
              margin: "20px",
              color: "#ECF0F1",
              textDecoration: "none",
              display: "block",
              fontSize: "32px",
            }}
          >
            Apie
          </ScrollLink>
          <ScrollLink
            to="dreams-section"
            smooth={true}
            duration={500}
            onClick={handleToggleMenu}
            style={{
              cursor: "pointer",
              margin: "20px",
              color: "#ECF0F1",
              textDecoration: "none",
              display: "block",
              fontSize: "32px",
            }}
          >
            Sapnai
          </ScrollLink>
          <ScrollLink
            to="reviews-section"
            smooth={true}
            duration={500}
            onClick={handleToggleMenu}
            style={{
              cursor: "pointer",
              margin: "20px",
              color: "#ECF0F1",
              textDecoration: "none",
              display: "block",
              fontSize: "32px",
            }}
          >
            Atsiliepimai
          </ScrollLink>
        </Box>
      </Modal>

      <Box
        sx={{
          position: "relative",
          overflowX: "hidden", // Prevent horizontal scrolling
          overflowY: "hidden", // Prevent vertical scrolling
          minHeight: "100vh", // Ensure the box takes the full viewport height
        }}
      >
        {starStyles.map((style, index) => (
          <Box
            key={index}
            component="img"
            src={starsImg}
            alt="Star"
            sx={{
              position: "absolute",
              ...style,
              animation: `${fadeIn} 3s ease-in-out forwards`,
              animationDelay: style.delay,
              width: "100px",
              zIndex: -100,
              opacity: 0,
            }}
          />
        ))}

        <Container
          component="main"
          maxWidth="md"
          sx={{
            mt: 2,
            textAlign: "center",
            animation: `${fadeIn} 1s ease-in-out`,
            paddingX: 0, // Ensure no extra padding is causing overflow
            overflow: "hidden", // Ensure container does not overflow
            minHeight: "100vh", // Ensure container height matches the viewport
            padding:2
          }}
        >
          <Typography
            id="about"
            variant="h4"
            gutterBottom
            sx={{ textTransform: "uppercase", color: "#ECF0F1" }}
          >
            Pažinkite save per savo sapnus
          </Typography>
          <Box
            sx={{
              width: "100%",
              textAlign: "center",
              mt: 4,
              opacity: imageLoaded ? 1 : 0,
              transition: "opacity 1s ease-in-out",
              zIndex: 1,
            }}
          >
            <img
              src={dreamerSvg}
              alt="Dreamer"
              style={{ maxWidth: "70%" }}
              onLoad={() => setImageLoaded(true)}
            />
          </Box>
          <Button
            variant="contained"
            onClick={handleStart}
            sx={{
              mt: 4,
              bgcolor: "#1ABC9C",
              width: "200px",
              height: "45px",
              fontSize: "22px",
            }}
          >
            Pradėti
          </Button>
          <Box
            sx={{
              mt: 2,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <DoubleArrowIcon
              sx={{
                fontSize: 50,
                color: "#ECF0F1",
                animation: `${bounce} 2s infinite`,
                transform: "rotate(90deg)",
              }}
            />
          </Box>

          {/* Section: Su Sapnasis galite */}
          <Typography
            id="about-section"
            variant="h4"
            sx={{ mt: 18, color: "#ECF0F1",pt:2 }}
          >
            Su Sapnasis galite:
          </Typography>
          <Grid container spacing={4} sx={{ mt: 2 }}>
            <Grid item xs={12} md={4}>
              <InsightsIcon sx={{ fontSize: 120, color: "#A29BFE" }} />
              <Typography variant="h5" sx={{ mt: 2, color: "#ECF0F1" }}>
                Sapnų analizė
              </Typography>
              <Typography sx={{ color: "#FAF9F6" }}>
                Analizuokite savo sapnus naudodami pažangias technologijas
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <AutoStoriesIcon sx={{ fontSize: 120, color: "#A29BFE" }} />
              <Typography variant="h6" sx={{ mt: 2, color: "#ECF0F1" }}>
                Sapnų istorija
              </Typography>
              <Typography sx={{ color: "#ECF0F1" }}>
                Išsaugokite savo sapnus savo paskyroje ir stebėkite jų istoriją
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <SecurityIcon sx={{ fontSize: 120, color: "#A29BFE" }} />
              <Typography variant="h6" sx={{ mt: 2, color: "#ECF0F1" }}>
                Duomenų saugumas
              </Typography>
              <Typography sx={{ color: "#ECF0F1" }}>
                Jūsų duomenys yra saugūs ir valdomi jūsų pačių
              </Typography>
            </Grid>
          </Grid>

          {/* Section: Kodėl sapnų analizė yra svarbi? */}
          <Typography
            id="dreams-section"
            variant="h4"
            sx={{ mt: 12,pt:2, color: "#ECF0F1"}}
          >
            Kodėl sapnų analizė yra svarbi?
          </Typography>
          <Typography
            variant="body1"
            sx={{
              mt: 8,
              color: "#ECF0F1",
              textAlign: "left",
              lineHeight: 2,
              fontSize: 18,
            }}
          >
            <strong style={{ color: "#E74C3C" }}>Sapnų analizė</strong> gali
            atskleisti gilias paslaptis apie mūsų{" "}
            <strong style={{ color: "#E74C3C" }}>pasąmonę</strong> ir{" "}
            <strong style={{ color: "#E74C3C" }}>emocinę</strong> būseną.
            Naudodamiesi <strong style={{ color: "#E74C3C" }}>Sapnasis</strong>,
            galite sužinoti, ką reiškia jūsų sapnai ir kaip jie gali padėti jums
            geriau <strong style={{ color: "#E74C3C" }}>suprasti save</strong>.
          </Typography>
          <Typography
            variant="body1"
            sx={{
              mt: 2,
              color: "#ECF0F1",
              textAlign: "left",
              lineHeight: 2,
              fontSize: 18,
            }}
          >
            Mūsų įrankis naudoja naujausius{" "}
            <strong style={{ color: "#E74C3C" }}>algoritmus</strong>, kad
            suteiktų jums{" "}
            <strong style={{ color: "#E74C3C" }}>
              išsamias ir tikslines sapnų analizės įžvalgas
            </strong>
            . Prisijunkite prie mūsų šiandien ir pradėkite savo kelionę į
            gilesnį savęs pažinimą.
          </Typography>

          {/* Button to Start */}
          <Button
            variant="contained"
            onClick={handleStart}
            sx={{
              mt: 16,
              mb: 12,
              bgcolor: "#1ABC9C",
              width: "200px",
              height: "45px",
              fontSize: "22px",
            }}
          >
            Pradėti
          </Button>

          {/* Section: Atsiliepimai */}
          <Typography
            id="reviews-section"
            variant="h4"
            sx={{ mt: 8, mb: 8, color: "#ECF0F1" }}
          >
            Atsiliepimai
          </Typography>
          <ReviewCarousel />
        </Container>
        <Footer />
      </Box>
    </>
  );
}

export default Home;
