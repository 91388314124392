import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  TextField,
  CardActions,
  Button,
  CircularProgress,
  Snackbar,
  SnackbarContent,
  IconButton,
  Typography,
  Box,
} from "@mui/material";
import { MonetizationOn, Close as CloseIcon } from "@mui/icons-material";

const DreamForm = ({
  dream,
  onChange,
  onSubmit,
  analyzing,
  error,
  onErrorClose,
}) => {
  const maxLength = 300;
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (error) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [error]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    onErrorClose();
  };

  return (
    <Card
      sx={{
        minWidth: { xs: "100%", sm: "70%", md: "60%", lg: "50%" },
        p: 1,
        boxShadow: "0",
        background: "transparent",
        mb: 2,
        minHeight: "250px", // Minimum height for the card
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        zIndex: 1,
      }}
    >
      <CardContent sx={{ zIndex: 1 }}>
        <Typography
          variant="h5"
          component="div"
          gutterBottom
          sx={{
            textTransform: "uppercase",
            textAlign: "center",
            color: "#FAF9F6",
          }}
        >
          Apibūdinkite savo sapną
        </Typography>
        <TextField
          fullWidth
          multiline
          rows={2}
          variant="outlined"
          margin="normal"
          placeholder="Sapnavau kaip mane vijosi vilkų būrys ir aš paslydau ant..."
          value={dream}
          onChange={onChange}
          inputProps={{ maxLength }}
          sx={{
            borderRadius: 2,
            color: "white",
            border: "1px solid #34495E",
            backgroundColor: "#5A6B82", // Transparent background color
            boxShadow: 5, // Box shadow
            "& .MuiOutlinedInput-root": {
              background: "transparent", // Ensure input root is transparent
              "& fieldset": {
                borderColor: "transparent",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#545891",
                borderRadius: 3,
              },
            },
            "& .MuiInputBase-input": {
              color: "white", // Text color
              padding: "5px", // Add some padding
              "&::placeholder": {
                color: "#ffffff", // Bright placeholder text color
                opacity: 1, // Ensures full opacity
              },
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderWidth: "0px",
            },
          }}
        />

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{ ml: "auto", color: "white" }}
          >
            {dream.length}/{maxLength}
          </Typography>
        </Box>
      </CardContent>
      <CardActions
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#1ABC9C",
            "&:hover": {
              backgroundColor: "#3F00FF",
            },
            "&:active": {
              backgroundColor: "#3F00FF",
            },
            borderRadius: 2,
            width: "200px",
            height: "40px",
          }}
          onClick={onSubmit}
          disabled={analyzing}
          endIcon={<MonetizationOn style={{ fill: "#FFD700" }} />}
        >
          {analyzing ? (
            <CircularProgress size={24} sx={{ color: "#FFFFFF" }} />
          ) : (
            "Analizuoti ( 1 )"
          )}
        </Button>
      </CardActions>
      <Snackbar
        open={open}
        autoHideDuration={10000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <SnackbarContent
          style={{
            backgroundColor: "#d32f2f",
            color: "#ffffff",
            position: "relative",
            padding: "16px",
            fontSize: "1rem",
          }} // Increased padding and font size
          message={<span style={{ marginRight: "30px" }}>{error}</span>} // Add margin to avoid overlap with the button
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
              sx={{ position: "absolute", top: "8px", right: "8px" }} // Adjusted position for larger size
            >
              <CloseIcon fontSize="medium" />
            </IconButton>
          }
        />
      </Snackbar>
    </Card>
  );
};

export default DreamForm;
